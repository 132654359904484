import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@oup/shared-front-end/src/components/Button/Button.js';
import styles from './ErrorView.scss';
import Illustration from '../../../components/Illustration/Illustration';

function ErrorView({
  title,
  bodyText,
  additionalText,
  bodyTextSecondLine,
  onButtonClick,
  hasButton = false,
  centerOnPage = false,
  hasTwoBodyTextLines = false
}) {
  const handleOnButtonClick = useCallback(() => {
    if (onButtonClick) onButtonClick();
  }, [onButtonClick]);

  return (
    <div className={classnames(styles.container, centerOnPage && styles.center_on_parent)}>
      <div className={styles.illustration}>
        <Illustration illustrationSrc="/media/feedback/illustration-error-frustrated.svg" />
      </div>
      {hasTwoBodyTextLines ? <h1 className={styles.header}>{title}</h1> : <h2 className={styles.header}>{title}</h2>}
      <p className={styles.body_text}>{bodyText}</p>
      {bodyTextSecondLine && <p className={styles.body_text_second_line}>{bodyTextSecondLine}</p>}
      {additionalText && <p className={styles.additional_text}>{additionalText}</p>}
      {hasButton && <Button variant="filled" size="base" text="Try again" onClick={handleOnButtonClick} />}
    </div>
  );
}

ErrorView.propTypes = {
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  additionalText: PropTypes.string,
  centerOnPage: PropTypes.bool,
  hasButton: PropTypes.bool,
  hasTwoBodyTextLines: PropTypes.bool,
  bodyTextSecondLine: PropTypes.string,
  onButtonClick: PropTypes.func
};

export default ErrorView;
